import plusSVG from "../assets/img/plus.svg";

export default {
  components: { plusSVG },
  data() {
    return {
      api: "api/news",
      timer: null,
      articles: [],
      currentPagination: 1,
      pagination: 1,
      elementPerPage: 3,
      elementsToDisplay: [],
      search: "",
      loading: true,
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  methods: {
    fetchArticles() {
      this.$axios.get(this.api).then((res) => {
        this.articles = res.data.articles;
        this.articles.forEach((a, i) => {
          a.translations = a.translations.filter((el) => {
            for (let al of this._global.availableLanguages) {
              if (al.value === el.lang) {
                return true;
              }
            }
          });
        });
        this.loading = false;
      });
    },
  },
};
