<template>
  <div class="property-list-container">
    <Header
      :details="{
        back: false,
        title: 'Jobs',
      }"
    />
    <div class="wrapper-content">
      <header class="header-list flex">
        <div class="flex sub-header-options">
          <div class="search"><input type="text" v-model="search" :placeholder="$t('overall.search')" /></div>
          <router-link :to="{ name: 'create-jobs' }" class="cta align-right">
            <plusSVG />
            {{ "Create Job" }}
          </router-link>
        </div>
      </header>
      <div class="table-list">
        <ul class="labels">
          <li
            @click="orderListBy('translations.title', articles)"
            class="table-list__line table-list__line--fixed-width"
            style="--width: 30%"
          >
            <button>{{ $t("overall.title") }}</button>
          </li>
          <li @click="orderListBy('translations.description', articles)" class="table-list__line">
            <button>{{ $t("Description") }}</button>
          </li>
        </ul>
        <div class="elements">
          <template v-if="!loading">
            <div class="line" v-for="a in articles" :key="a.token">
              <router-link :to="{ name: 'edit-jobs', params: { token: a.token } }">
                <div class="table-list__line table-list__line--fixed-width" style="--width: 30%">
                  {{ a.translations.length && a.translations[0].title ? a.translations[0].title : "-" }}
                </div>
                <div class="table-list__line">
                  {{
                    a.translations.length && a.translations[0].description
                      ? a.translations[0].description.replace(/(&lt;([^>]+)>)/gi, "").substring(0, 200)
                      : "-"
                  }}
                </div>
              </router-link>
            </div>
          </template>
          <span class="no-element" v-else>{{ "Loading Jobs" }}</span>
          <span class="no-element" v-if="articles.length < 1 && !loading">No Jobs</span>
        </div>
      </div>
      <Pagination v-if="articles.length" :value="articles" @changeCurrentPage="elementsToDisplay = $event" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import plusSVG from "@/assets/img/plus.svg";
import modalBus from "@/bus/modal-bus";
import articleListGeneric from "@/mixins/articleListGeneric";

export default Vue.extend({
  components: { plusSVG },
  name: "JobsList",
  mixins: [articleListGeneric],
  data() {
    return {
      api: "api/jobs",
    };
  },
  methods: {},
  created() {
    this.fetchArticles();
  },
});
</script>

<style lang="scss" scoped></style>
